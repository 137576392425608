import restaurant from "./images/noroc.jpg";
import biserica from "./images/biserica.jpg";
import img_card from "./images/picioruse.jpg";
import img_florin from "./images/florin.jpeg";
import imgheadermb from "./images/panda.webp";
import imgheader from "./images/panda.webp";
import imgheadermiini from "./images/panda4.jpg";
import imgheadermiinimb from "./images/panda3.jpg";
import logo from './images/panda-logo.png'
import img_muz from "./images/music.png"
import audio from "./audio/Florin.mp3"


const data = {
    introData: [{
        copilul: "семья Падуряну",
        familia: "семья Падуряну",
        logo: logo,
        tata: "Андрей",
        mama: "Ангелина",
        data: "19 Апрелья 2023",
        data_confirmare: "",
        savedata: "~ Приглашают! ~",
        imgdesktop: imgheader,
        imgmobil: imgheadermb,
        email: "anusic007@gmail.com", 
       tel: "+37378182222",
       phone: "tel:+37378182222",
       viber: "viber://chat?number=%2B37378182222",
       whatsapp: "https://wa.me/+37378182222",
       messenger: "https://www.messenger.com/t/100088431579879",
    }],
    cardData: [
        {
            id: 1,
            img: restaurant,
            title: "",
            localul: "Ресторан",
            name: "Casa Nunții Noroc",
            data: "19 апрелья 2022, среда, в 17:00 ч.",
            adress: "ул. Сучевица 34, Кишинёв",
            harta: "https://goo.gl/maps/kBYTaTBAku959wmA8",
            iframe: "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d10877.687895441799!2d28.77855!3d47.03195!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40c97dfaf75678b1%3A0xe4f252e56de89e8f!2zQ2FzYSBOdW7Im2lpIOKAnk5vcm9j4oCd!5e0!3m2!1sru!2s!4v1678357850637!5m2!1sru!2s"
        },

    ],
    blogDataNoi: [
        {
            id: 1,
            img: img_card,
            title1: "",
            title2: "",
            message: "Дети - это подарок от Бога, повод верить в чудеса, они просто счастье на Земле.",
        }

    ],
    blogDataNasii: [
        {
            id: 2,
            img: img_florin,
            title1: "Invitație",
            title2: "Florin Salam",
            message: "In ziua in care ne vom uni destinele, vrem să fim înconjurați de cei mai apropiați și dragi oameni nouă. În acest context avem plăcerea de a va invita să ne fiți martori și să împărtășiți bucuria celui mai semnificativ eveniment din viața noastră.",
        muz: audio,
        music: img_muz,
        }
    ],
    blogDataOmSuflet: [
        {

            img: '',
            title: "Oameni de suflet!",
            message: "Alături de care vom scrie povestea noastră.",
            nasii: "",
            nasii_nume: "",
        }
    ],
    blogDataDrum: [
        {
            imgdesktop: imgheadermiini,
            imgmobil: imgheadermiinimb,
            title: "Salutare!",
            message: "Dacă ați ajuns aici înseamnă că ținem la voi și ne-am bucura enorm dacă veți fi alături de noi într-o zi atât de specială!",
        }
    ],

}

export default data;