import React from "react";



import { ParallaxProvider } from 'react-scroll-parallax';
import '../css/Timer.css';
import '../css/Body.css';
import Timer from "./Timer";
import Parallaximg from "../Parallaximg";



import CardLocal from "../cardLocal";


import FormSection from "../FormSection";

import BlogSectionNoi from "../blogSectionNoi";
import BlogSectionNasii from "../blogSectionNasii";
import BlogSectionAdrian from "../blogSectionAdrian";
import BlogSectionNatalia from "../blogSectionNatalia";
import BlogSectionCristina from "../blogSectionCristina";

import data from "../Data";
import CardInvitatie from "./CardInvitatie";
import Gallery from "./Gallery";

import rama1 from '../images/1.jpg';
import rama2 from '../images/2.jpg';
import rama3 from '../images/3.jpg';

const Home = (props) => {


    return (



        <ParallaxProvider>

            {data.introData.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height="600px" opacity=".8" >
                        <div className="row text-center align-items-center" style={{ position: 'absolute' }}>
                            <div className="col-12 mb-5" style={{ maxWidth: '600px', padding: '20px' }}>
                                <h1 className="animate__animated animate__bounceInLeft" style={{ fontSize: '55px' }}>Горице</h1>
                                <h5 className="animate__animated animate__pulse animate__delay-4s animate__infinite" style={{ fontSize: '35px' }}> <svg width="1.5em" height="1.5em" color="red" viewBox="0 0 16 16" class="bi bi-suit-heart-fill"
                                    fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M4 1c2.21 0 4 1.755 4 3.92C8 2.755 9.79 1 12 1s4 1.755 4 3.92c0 3.263-3.234 4.414-7.608 9.608a.513.513 0 0 1-.784 0C3.234 9.334 0 8.183 0 4.92 0 2.755 1.79 1 4 1z" />
                                </svg></h5>
                                <h1 className="animate__animated animate__bounceInLeft" style={{ fontSize: '55px' }}>Мухаммад и Руслан</h1>
                                <h4 className="animate__animated animate__bounceInRight animate__delay-2s animate__slower" style={{ fontSize: '36px' }}>{item.savedata}<br />{item.data}</h4>

                            </div>
                        </div>
                    </Parallaximg>
                )
            })}



            <div id="deti" className="text-center p-2 m-2">
                <span className="container-img" style={{ position: 'relative', color: 'white', textShadow: '1px 1px 2px rgb(7, 7, 7)', display: "inline-block" }}>
                    <img className="rama" src={rama1} />
                    <div className="text-img" style={{ position: 'absolute', bottom: "20%", left: '17.5%' }}>
                        <h2>Мухаммад</h2>
                    </div>
                </span>
                <span className="container-img" style={{ position: 'relative', color: 'white', textShadow: '1px 1px 2px rgb(7, 7, 7)', display: "inline-block" }}>
                    <img className="rama" src={rama2} />
                    <div className="text-img" style={{ position: 'absolute', bottom: "20%", left: '27.5%' }}>
                        <h2>Горице</h2>
                    </div>
                </span>
                <span className="container-img" style={{ position: 'relative', color: 'white', textShadow: '1px 1px 2px rgb(7, 7, 7)', display: "inline-block" }}>
                    <img className="rama" src={rama3} />
                    <div className="text-img" style={{ position: 'absolute', bottom: "20%", left: '27.5%' }}>
                        <h2>Руслан</h2>
                    </div>
                </span>
            </div>



            <BlogSectionNoi />

            <div className="container" style={{ maxWidth: '600px', padding: '20px' }}>
              
            </div>

          


            {data.blogDataDrum.map((item) => {
                return (
                    <Parallaximg imgsrc={window.innerWidth >= 850 ? item.imgdesktop : item.imgmobil} height="600px" opacity=".8">
                
                        <div className="row text-center align-items-center" style={{ position: 'absolute' }}>
                            <div className="col-12 mb-3" style={{ maxWidth: '600px', padding: '20px' }}>
                                 <Timer /> 
                               
                            </div>
                        </div>
                    </Parallaximg>
                )
            })}


            <CardInvitatie />



            <div className="container text-center pt-5">
                <h2>На нашем празднике вы не будите скучать!</h2>
            </div>
            <BlogSectionNasii />
            <BlogSectionAdrian/>
            <BlogSectionCristina/>
            <BlogSectionNatalia/>
            <CardLocal />


            <FormSection />

            {data.introData.map((item) => {
                return (
                    <div>
                        <h4 className="text-center mt-5" >
                            Ждем Вас!
                        </h4>

                        <h3 className="text-center mb-5" style={{ fontFamily: 'Dancing Script', fontSize: '30px' }}>
                            {item.familia}
                        </h3>
                    </div>

                )
            })}





        </ParallaxProvider>



    )
}

export default Home;