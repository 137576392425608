import { WhatsApp } from "@mui/icons-material";
import React from "react";
import PhoneIcon from '@mui/icons-material/Phone';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import MessageIcon from '@mui/icons-material/Message';

import FormValidation from "./components/FormValidation";

import data from "./Data"


const FormSection = (props) => {
 
  return (
    <>
      <section id="forms" className="form" >
      <h2 className="text-center mt-5" style={{ fontFamily: 'Dancing Script', fontSize: '40px' }}>Подтверждение присутствия</h2>
          
        
      {data.introData.map((item) => { 
        return(
          
          
           <div class="card text-center container" style={{ maxWidth: "55rem" }}>
          <div class="card-header" >
          Будем признательны, если вы подтвердите свое присутствие.
          </div>
       

       {/**  <FormValidation/>*/}  

          <div class="card-footer text-muted">
          Если у вас есть другие вопросы, пожалуйста, свяжитесь с нами или напишите нам сообщение:<br />
            {item.tata}  {item.tel} : <br /><a class=" m-2 btn_logo" href={item.phone}> <PhoneIphoneIcon /> Phone</a>
           <a className="m-2 btn_logo" href={item.viber}> <PhoneIcon />Viber</a>
           <a className="m-2  btn_logo" href={item.whatsapp}><WhatsApp />WhatsApp</a>
            <a className=" m-2  btn_logo" href={item.messenger}><MessageIcon />Messenger</a><br />
            
          {/* Dinu +xxxxx : <br /><a class=" m-2 btn_logo" href="tel:+xxx"> <PhoneIphoneIcon /> Phone</a>
            <a className="m-2 btn_logo" href="viber://chat?number=%2Bxxx"> <PhoneIcon />Viber</a>
            <a className="m-2 btn_logo" href="https://wa.me/+xxx"><WhatsApp />WhatsApp</a>
            <a className=" m-2 btn_logo" href="https://www.messenger.com/t/dinu.vicol1998"><MessageIcon />Messenger</a>
          */} 
          </div>
        </div> )
      })}
          
    
      </section>
    </>
  )


}

export default FormSection;