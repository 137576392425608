import React from "react";
import data from "./Data.js"
import Blog from "./components/Blog";
import music from "./images/music.png"
import muz from "./audio/Florin.mp3"
import img from "./images/florin.jpeg";

const BlogSection = () => {
    return (
        <section className="blog pt-5"  >
           
            
                {data.blogDataNasii.map((item, index) => {
                    return (
                        <>
                         
                      {/** <Blog key={index} id={item.id} img={item.img} title1={item.title1} title2={item.title2} message={item.message} music={item.music} />*/}  
                       
                      <div className="container blog-container mt-4">
        <div className="row main-row ">
          <div className="col-lg-4 col-md-12 col-sm-12">
            <div className="blog-img text-center">
              <img className="img-fluid" src={img} />
            </div>
          </div>
          <div className="col-lg-8 col-md-12 col-sm-12">
            <div className="blog-title mt-lg-5 mt-md-2 mt-sm-1">
              <h5 className="text-center">
                
              </h5>
              <h2 className="text-center p-2">
              Florin Salam
              </h2>
              <h4 className="text-center">
                
              </h4>

              <div className="text-center">
                        <span className="text-center">
                            <img className="music animate__animated animate__pulse animate__delay-4s animate__infinite" src={music} />
                            <audio src={muz} controls autoPlay loop ></audio>
                        </span>
                    </div>

            </div>
          </div>
        </div>
      </div> 
                       
                       
                       
                       
                        </>
                        
                       
                    
                        )
                })}


           
        </section>


    )


}

export default BlogSection;